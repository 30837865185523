import createStore from 'unistore'
import unistoreDevtools from 'unistore/devtools' ///DEV_ONLY
import { jwtDecode, type JwtPayload } from 'jwt-decode'

import {
  addNotification,
  removeNotification,
} from '@modwatch/core/store'
import { clearLocalState, setLocalState, getLocalState } from './local'
import { insertScriptIntoDom } from './dom'

import { GlobalState } from '../types'

const user = getLocalState()

export const rawState = {
  notifications: [],
  user: user,
  adsense: {
    loaded: false,
    failed: false,
  },
}

let _store = createStore(rawState)

_store = unistoreDevtools(_store) ///DEV_ONLY
export const store = _store

export const actions = store => ({
  login(state: GlobalState, token) {
    const { sub, scopes } = jwtDecode<JwtPayload & { scopes: string }>(token)
    const user = {
      token,
      authenticated: true,
      username: sub,
      scopes,
    }
    setLocalState(user)
    return {
      ...state,
      user,
    }
  },
  logout(state: GlobalState) {
    return {
      ...state,
      user: clearLocalState(),
    }
  },
  async loadAdsenseAds(state: GlobalState) {
    if (!process.env.ADSENSE_ENABLED) {
      return {
        ...state,
        adsense: {
          loaded: false,
          failed: true,
        },
      }
    }
    if (state.adsense.loaded || state.adsense.failed) {
      return state
    }
    try {
      await insertScriptIntoDom(
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
      )
      return {
        ...state,
        adsense: {
          loaded: true,
          failed: false,
        },
      }
    } catch (e) {
      store.setState(
        actions(store).addNotification(store.getState(), 'Ads Blocked'),
      )
      return {
        ...state,
        adsense: {
          loaded: false,
          failed: true,
        },
      }
    }
  },
  addNotification,
  removeNotification,
})
